<script>
	import {emitter} from '../utils/emitter';
	import { h, ref, reactive, onMounted, resolveComponent } from 'vue';

	const bootstrap = require('bootstrap');

	export default {
		name: 'ModalComponent',
		setup() {
			const component = ref('div');
			const properties = reactive({});
			const title = ref('');

			const resetModalProperties = () => {
				component.value = 'div';
				properties.value = {};
				title.value = '';
			};

			const hideModal = () => {
				resetModalProperties();

				let bm = new bootstrap.Modal(document.getElementById('mainModal'));
				bm.hide();
			}

			const listener = () => {
				emitter.on('showModal', data => {
					component.value = data.component;
					properties.value = data.props;
					title.value = data.title;

					let bm = new bootstrap.Modal(document.getElementById('mainModal'));
					bm.show();
				});

				emitter.on('modalTitle', data => {
					title.value = data.title;
				});

				emitter.on('hideModal', () => {
					hideModal();
				});

				document.getElementById('mainModal').addEventListener('hidden.bs.modal', function () {
					hideModal();
				});
			}

			onMounted(listener);

			return {
				component,
				properties,
				title
			}
		},
		render() {
			return h(
				'div',
				{
					class: 'modal fade',
					tabindex: -1,
					id: 'mainModal'
				},
				[
					h(
						'div',
						{
							class: 'modal-dialog modal-dialog-scrollable modal-dialog-centered'
						},
						[
							h(
								'div',
								{
									class: 'modal-content'
								},
								[
									h(
										'div',
										{
											class: 'modal-header'
										},
										[
											h(
												'h5',
												{
													class: 'modal-title'
												},
												this.title
											),
											h(
												'button',
												{
													type: 'button',
													class: 'btn btn-close',
													'data-bs-dismiss': 'modal',
													'aria-label': 'Close'
												}
											)
										]
									),
									h(
										'div',
										{
											class: 'modal-body'
										},
										[
											h(
												this.component !== 'div' ? resolveComponent(this.component) : this.component,
												this.properties.value
											)
										]
									)
								]
							)
						]
					)
				]
			);
		}
	}
</script>
