let modelData = {
	ID: null,
	First_Name: '',
	Last_Name: '',
	Email: null,
	IMAGE_Photo: null,
	Active: null,
	Admin: null,
};

const user = {
	data() {
		return {
			assignee: {...modelData},
			user: {...modelData},
		}
	},
	methods: {
		setUserModel(model, data) {
			Object.assign(model, data);
		},
		getFullName(model) {
			return model.First_Name + ' ' + model.Last_Name;
		}
	}
};

export default user;