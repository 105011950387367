import axios from 'axios';

let url = 'https://pm-api.wisnet.com/api/';
// let url = 'http://pm-api.test:8888/api/';
let token = '12|WVv1xKyjvdCEBeUVLDeBKpXZzNPurc1PFtXn3A3t';
// local token
// let token = '1|8Vkp4jc4i02Sw2e2kVwwsPJE6XkmfRlzY9TyBluh';

axios.interceptors.request.use(function (config = {}) {
	// Do something before request is sent
	config.headers.Authorization = 'Bearer ' + token;
	config.headers['Access-Control-Allow-Credentials'] = 'true';
	config.headers['X-Requested-With'] = 'XMLHttpRequest';
	config.headers['Content-Type'] = 'application/json;charset=UTF-8';
	config.baseURL = url;

	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});