const client = {
	data() {
		return {
			client: {
				ID: null,
				Company: null,
				Address1: null,
				Address2: null,
				City: null,
				State: null,
				Zip: null,
				Email: null,
				Domain: null,
				Active: null,
			}
		}
	},
	methods: {
		setClient(data) {
			Object.assign(this.client, data);
		}
	}
};

export default client;