import { createStore } from 'vuex';

import task from './modules/task';
import scheduler from './modules/scheduler';

const store = createStore({
	modules: {
		task,
		scheduler
	}
});

export default store;