import axios from 'axios';

const task = {
	namespaced: true,
	actions: {
		fetchTask({}, {id}) {
			return axios.get('tasks/' + id);
		},
		fetchTasks({}, {data}) {
			return axios.post('tasks', data);
		}
	}
};

export default task;