const project = {
	data() {
		return {
			project: {
				ID: null,
				Company: null,
				Client: null,
				ProjectName: null,
				Description: null,
				CreatedBy: null,
				CreatedDate: null,
				Active: null,
				Status: null,
			}
		}
	},
	methods: {
		setProject(data) {
			Object.assign(this.project, data);
		}
	}
};

export default project;