<template>
	<SchedulerActions/>
	<div class="scheduler-container row">
		<div class="col-12" style="height: 100%">
			<DayPilotScheduler
				id="dp"
				:config="config"
				ref="scheduler"
			/>
		</div>
	</div>
</template>

<script>
	import {DayPilot, DayPilotScheduler} from 'daypilot-pro-vue';

	import TaskModal from './tasks/TaskModal';
	import SchedulerActions from './SchedulerActions';
	import {mapState} from 'vuex';

	export default {
		name: 'SchedulerComponent',
		components: {
			DayPilotScheduler,
			TaskModal,
			SchedulerActions
		},
		setup() {
			return {
				zoomLevels: [
					{
						name: 'Month',
						properties: {
							scale: 'Day',
							cellWidth: 40,
							cellDuration: 15,
							timeHeaders: [
								{groupBy: 'Month'},
								{groupBy: 'Day', format: 'd'}
							],
						}
					},
					{
						name: 'Week',
						properties: {
							scale: 'Hour',
							cellWidth: 40,
							cellDuration: 1440,
							timeHeaders: [
								{groupBy: 'Month'},
								{groupBy: 'Day', format: 'dddd d'},
								{groupBy: 'Hour'}
							],
						}
					},
					{
						name: 'Day',
						properties: {
							scale: 'Hour',
							cellDuration: 60,
							cellWidth: 80,
							timeHeaders: [
								{groupBy: 'Month'},
								{groupBy: 'Day', format: 'dddd d'},
								{groupBy: 'Hour'},
							],
						}
					},
				],
			}
		},
		data() {
			return {
				config: {
					days: DayPilot.Date.today().daysInYear(),
					resources: [],
					events: [],
					DurationBarVisible: true,
					DurationBarMode: 'PercentComplete',
					eventClickHandling: 'CallBack',
					treeEnabled: true,
					infiniteScrollingEnabled: true,
					dynamicLoading: true,
					loadingLabelText: 'Loading...',
					loadingLabelVisible: true,
					useEventBoxes: 'Never',
					heightSpec: 'Parent100Pct',
					onEventClicked: (args) => {
						let task = args.e.data;
						this.$modal.show('TaskModal', {id: task.id}, task.text);
					},
					onScroll: (args) => {
						this.scheduler.loadingStart();

						let start = args.viewport.start.toString();
						let end = args.viewport.end.toString();

						let events = [];

						this.$store.dispatch('task/fetchTasks', {data: {start, end}}).then(data => {
							for (const task of data.data.tasks) {
								events.push(task);
							}

							for (const task of data.data.on_hold) {
								events.push(task);
							}

							args.events = events;
							args.loaded();

							this.scheduler.loadingStop();
						});
					},
					onTimeHeaderClicked: args => {
						let level = args.header.level;
						let date = args.header.start;

						switch (this.zoomLevel) {
							case 0:
								// Month view
								if (level === 1) {
									this.setDateAndZoomLevel(date, 2);
								}
								break;
							case 1:
								// Week view
								if (level === 0) {
									this.setDateAndZoomLevel(date, 0);
								}

								if (level === 1) {
									this.setDateAndZoomLevel(date, 2);
								}
								break;
							case 2:
								// Day view
								if (level === 0) {
									this.setDateAndZoomLevel(date, 0);
								}
								break;
						}
					},
					onRowFilter: args => {
						if (args.row.name === 'On Hold') {
							args.visible = args.filterParam.onHoldVisible;
						}
					}
				},
				selectedDate: DayPilot.Date.today().firstDayOfMonth()
			}
		},
		computed: {
			...mapState({
				zoomLevel: state => state.scheduler.zoomLevel,
				showOnHold: state => state.scheduler.showOnHold,
			}),
			scheduler: function () {
				return this.$refs.scheduler.control;
			},
		},
		created() {
			this.fetchResources();
		},
		mounted() {
			this.scheduler.zoomLevels = this.zoomLevels;
			this.scheduler.zoom.setActive(this.zoomLevel, 'middle');
			this.scheduler.scrollTo(this.selectedDate);
		},
		methods: {
			async fetchResources() {
				await this.$store.dispatch('scheduler/fetchResources').then(data => {
					for (const resource of data.data) {
						this.config.resources.push(resource);
					}
				});
			},
			changeZoomLevel() {
				this.scheduler.zoom.setActive(parseInt(this.zoomLevel), 'middle');
				this.scheduler.scrollTo(this.selectedDate);
			},
			setDateAndZoomLevel(date, level) {
				// Set the date to scroll to
				this.selectedDate = date;

				// Set zoom level
				this.$store.dispatch('scheduler/setZoomLevel', {level: level});
			}
		},
		watch: {
			zoomLevel(newVal, oldVal) {
				if (oldVal !== newVal) {
					this.changeZoomLevel();
				}
			},
			showOnHold(val) {
				this.scheduler.rows.filter({ onHoldVisible: val});
			},
		}
	}
</script>

<style>
	.scheduler_default_event_bar_inner {
		width: 100% !important;
	}
	.scheduler-container {
		height: 90%;
	}
</style>